module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"skipWaiting":true,"clientsClaim":true,"cacheId":"gatsby-plugin-offline","dontCacheBustUrlsMatching":{},"importWorkboxFrom":"local","runtimeCaching":[{"urlPattern":{},"handler":"cacheFirst"},{"urlPattern":{},"handler":"staleWhileRevalidate"},{"urlPattern":{},"handler":"staleWhileRevalidate"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"typography":{"useNextVariants":true,"fontSize":18},"palette":{"primary":{"main":"#004262"},"secondary":{"light":"#000","main":"#000"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-137158856-1"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":["/demo"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"jei0pkb"}},
    }]
